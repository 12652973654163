import styled from '@emotion/styled'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Badge, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Breakpoint from '../../../Breakpoint'
import { getAllUser, resetTournamentMoney } from '../../../api/admin-user.api'
import files from '../../../assets/templates-files/files'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import SearchInput from '../../../components/global/SearchInput'
import Title from '../../../components/global/Title'
import TooltipBva from '../../../components/global/TooltipBva'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { formatTimeInSeconds } from '../../../services/time.service'
import { UserModel, roles } from '../../../types/user.type'

const helpTextModel = (
    <>
        Il est recommandé d'utiliser un fichier CSV dont les données sont séparé par des virgules pour remplir les
        données.
    </>
)

const filteredAdmins = (
    users: Omit<UserModelForAdminTable, UserModelForAdminTableOmittedKeys>[],
    res: AxiosResponse<any, any>
) => users.filter((user: { id: number }) => user.id !== res.data.id)

type UserModelForAdminTableOmittedKeys = 'dayOfBirth' | 'firstname' | 'lastname' | 'gender' | 'tournamentMoney'
type UserModelForAdminTable = {
    id: number
    firstname: string
    lastname: string
    pseudo: string
    email: string
    gender: string
    dayOfBirth: string
    tournamentMoney: string
    edit: string
    remove: boolean
}

const AdminUser = () => {
    const { t } = useTranslation('translation')

    const [searchTerm, setSearchTerm] = useState('')
    const [filterMembersToDisplay, setFilterMembersToDisplay] = useState<any[]>()

    const user = useContext(UserContext)
    const [admins, setAdmins] = useState<
        Omit<UserModelForAdminTable, UserModelForAdminTableOmittedKeys>[] | undefined
    >()
    const [members, setMembers] = useState<UserModelForAdminTable[] | undefined>()
    const [membersToDisplay, setMembersToDisplay] = useState<
        Omit<UserModelForAdminTable, 'gender' | 'dayOfBirth'>[] | undefined
    >()
    const [importMemberModal, setImportMemberModal] = useState<boolean>(false)
    const [tournamentMoneyModal, setTournamentMoneyModal] = useState<boolean>(false)
    const [importFile, setImportFile] = useState<File | undefined>()

    const [renewalModal, setRenewalModal] = useState<boolean>(false)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const tableHeaderAdmin = ['#', "Nom d'utilisateur", 'Email', '', '']
    const tableHeaderMember = ['#', 'Prénom', 'Nom', "Nom d'utilisateur", 'Email', 'Compte tournoi', '', '']

    const sortUsers = (users: UserModel[]) => {
        setAdmins(
            users
                .filter((user: UserModel) => user.role === roles.ROLE_ADMIN)
                .map((user: UserModel) => ({
                    id: user.id,
                    pseudo: user.pseudo,
                    email: user.email,
                    edit: `/${lng}/bva-admin/utilisateurs/${user.id}`,
                    remove: true,
                }))
        )

        setMembers(
            users
                .filter((user: UserModel) => user.role === roles.ROLE_MEMBER)
                .map((user: UserModel) => ({
                    id: user.id,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    pseudo: user.pseudo,
                    email: user.email,
                    gender: user.gender,
                    dayOfBirth: moment(user.dayOfBirth).format('DD/MM/yyyy'),
                    tournamentMoney: user.tournamentMoney + '€',
                    edit: `/${lng}/bva-admin/utilisateurs/${user.id}`,
                    remove: true,
                }))
        )

        setMembersToDisplay(
            users
                .filter((user: UserModel) => user.role === roles.ROLE_MEMBER)
                .map((user: UserModel) => ({
                    id: user.id,
                    firstname: user.firstName,
                    lastname: user.lastName,
                    pseudo: user.pseudo,
                    email: user.email,
                    tournamentMoney: user.tournamentMoney + '€',
                    edit: `/${lng}/bva-admin/utilisateurs/${user.id}`,
                    remove: true,
                }))
        )
    }

    const { data: _users, refetch: refetchUser } = useQuery({
        queryKey: ['document-user-all'],
        queryFn: async () => {
            verifyToken()
            const users = (await getAllUser()) as UserModel[]
            sortUsers(users)
            return users
        },
    })

    const { mutateAsync: doResetTournamentMoney } = useMutation({
        mutationFn: async () => {
            resetTournamentMoney(lng)
        },
        onSuccess: () => {
            let time = 10 * (members!.filter((user) => user.tournamentMoney + '' !== '0€').length - 1)
            if (time < 0) time = 0
            sweetAlertSuccess(`La réinitialisation s'est bien lancé. Elle prendra ${formatTimeInSeconds(time)}.`).then(
                () => {
                    refetchUser()
                }
            )
        },
    })

    useEffect(() => {
        setFilterMembersToDisplay(
            membersToDisplay?.filter((user) =>
                `${user.firstname} ${user.lastname} ${user.pseudo} ${user.email}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
        )
    }, [searchTerm, membersToDisplay])

    if (!admins || !members || !membersToDisplay || !filterMembersToDisplay) return <Loader />

    const editTournamentMoney = () => {
        verifyToken()
        if (importFile) {
            const reader = new FileReader()
            reader.readAsText(importFile)
            reader.onload = function (e: any) {
                const csvSplitByLine: string[] = e.target.result.split('\r\n')
                const separator = csvSplitByLine[0].includes(';') ? ';' : ','
                let csvLineSplitByCells: string[][] = csvSplitByLine.map((line: string) =>
                    line.replaceAll('"', '').split(separator)
                )

                csvLineSplitByCells = csvLineSplitByCells.filter(
                    (cell, index) => index !== 0 && cell.length === csvLineSplitByCells[0].length
                )

                axios
                    .post(
                        `${SERVER_URL}/users/import/tournament-money-by-csv-data`,
                        csvLineSplitByCells,
                        apiHeader(user.tokenAdmin)
                    )
                    .then((res) => {
                        // prettier-ignore
                        sweetAlertSuccess(`${res.data} comptes tournois ont été modifié.`).then(() => { // NOSONAR
                            window.location.reload()
                        })
                        setImportMemberModal(false)
                    })
                    .catch((err) => {
                        catchError(err.response.data)
                    })
            }
        }
    }

    const sendRenewalMailByCSV = () => {
        verifyToken()
        if (importFile) {
            const reader = new FileReader()
            reader.readAsText(importFile)
            reader.onload = function (e: any) {
                const csvSplitByLine: string[] = e.target.result.split('\r\n')
                let csvLineSplitByCells: string[][] = csvSplitByLine.map((line: string) =>
                    line.replaceAll('"', '').split(',')
                )

                csvLineSplitByCells = csvLineSplitByCells.filter(
                    (cell, index) => index !== 0 && cell.length === csvLineSplitByCells[0].length
                )

                axios
                    .post(`${SERVER_URL}/users/send/renewal`, csvLineSplitByCells, apiHeader(user.tokenAdmin))
                    .then(() => {
                        sweetAlertSuccess(
                            `Les membres non re-inscrit vont recevoir un mail de renouvellement. Ce processus peut prendre un certain temps selon le nombre de mail à envoyer.`
                        ).then(
                            // prettier-ignore
                            () => { // NOSONAR
                                window.location.reload()
                            }
                        )
                        setImportFile(undefined)
                        setRenewalModal(false)
                    })
                    .catch((err) => {
                        catchError(err.response.data)
                    })
            }
        }
    }

    const importCsvData = () => {
        verifyToken()
        if (importFile) {
            const reader = new FileReader()
            reader.readAsText(importFile)
            reader.onload = function (e: any) {
                const csvSplitByLine: string[] = e.target.result.split('\r\n')
                let csvLineSplitByCells: string[][] = csvSplitByLine.map((line: string) =>
                    line.replaceAll('"', '').split(',')
                )

                csvLineSplitByCells = csvLineSplitByCells.filter(
                    (cell, index) => index !== 0 && cell.length === csvLineSplitByCells[0].length
                )

                axios
                    .post(`${SERVER_URL}/users/import/members`, csvLineSplitByCells, apiHeader(user.tokenAdmin))
                    .then(() => {
                        sweetAlertSuccess(
                            `Les membres manquants vont être ajoutés. Ce processus peut prendre un certains temps selon le volume des adhérents à inscrire.`
                        ).then(
                            // prettier-ignore
                            () => { // NOSONAR
                                window.location.reload()
                            }
                        )
                        setImportMemberModal(false)
                    })
                    .catch((err) => {
                        catchError(err.response.data)
                    })
            }
        }
    }

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/users/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de cette utilisateur est réussi').then(() => {
                    setAdmins([...filteredAdmins(admins, res)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Utilisateurs</Title>
                <HeaderBtnWrapper>
                    <Btn
                        onClick={() => {
                            verifyToken()
                            setImportMemberModal(true)
                        }}
                    >
                        Importer des adhérents
                    </Btn>
                    <Btn onClick={() => setRenewalModal(true)}>Envoyer les mails des renouvellements</Btn>
                    <LinkBtn to={`/${lng}/bva-admin/utilisateurs/creer`} className="btn btn-primary">
                        Créer un utilisateur
                    </LinkBtn>
                </HeaderBtnWrapper>
            </TitleWrapper>
            {importMemberModal && (
                <ModalStyled
                    centered
                    show={true}
                    onHide={() => {
                        setImportMemberModal(false)
                        verifyToken()
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Ajouter des membres</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            L'export se trouve sur <a href="https://poona.ffbad.org/page.php?P=bo/index">Poona</a>.
                        </div>
                        <TooltipBva
                            tootipText={
                                <div
                                    style={{
                                        width: 250,
                                        padding: '1rem',
                                        background: 'black',
                                        borderRadius: 8,
                                    }}
                                >
                                    {helpTextModel}
                                </div>
                            }
                            placement="right"
                        >
                            <StyledButtonA href={files.model_adherents} className="btn btn-primary" download>
                                Télécharger le model
                            </StyledButtonA>
                        </TooltipBva>
                        <ImportInput
                            type="file"
                            accept=".csv"
                            onChange={(e) => e.target?.files?.[0] && setImportFile(e.target.files[0])}
                        />
                        <StyledButton variant="primary" type="button" onClick={() => importCsvData()}>
                            Importer
                        </StyledButton>
                    </Modal.Body>
                </ModalStyled>
            )}

            {renewalModal && (
                <ModalStyled
                    centered
                    show={true}
                    onHide={() => {
                        setRenewalModal(false)
                        verifyToken()
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Envoyer les formulaires de renouvellement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            L'export se trouve sur <a href="https://poona.ffbad.org/page.php?P=bo/index">Poona</a>.
                        </div>{' '}
                        <TooltipBva
                            tootipText={
                                <div
                                    style={{
                                        width: 250,
                                        padding: '1rem',
                                        background: 'black',
                                        borderRadius: 8,
                                    }}
                                >
                                    {helpTextModel}
                                </div>
                            }
                            placement="right"
                        >
                            <StyledButtonA href={files.model_renouvellement} className="btn btn-primary" download>
                                Télécharger le model
                            </StyledButtonA>
                        </TooltipBva>
                        <ImportInput
                            type="file"
                            accept=".csv"
                            onChange={(e) => e.target?.files?.[0] && setImportFile(e.target.files[0])}
                        />
                        <StyledButton variant="primary" type="button" onClick={() => sendRenewalMailByCSV()}>
                            Envoyer les mails
                        </StyledButton>
                    </Modal.Body>
                </ModalStyled>
            )}

            <AdminContainer>
                <TableTitle>
                    Liste des administrateurs <BvaBadge>{admins.length}</BvaBadge>
                </TableTitle>
                <UserTableWrapper>
                    <Table tableData={admins} tableHeader={tableHeaderAdmin} deleteById={deleteById} />
                </UserTableWrapper>

                <TitleWrapper>
                    <TableTitle>
                        Liste des membres <BvaBadge>{members.length}</BvaBadge>
                    </TableTitle>{' '}
                    <BtnWrapper>
                        <Btn
                            variant="primary"
                            type="button"
                            onClick={() => {
                                verifyToken()
                                navigator.clipboard.writeText(members.map((mem) => mem.email).join(';'))
                                sweetAlertSuccess('Les adresses mail sont copiées.')
                            }}
                        >
                            <IconBtn icon={faCopy} /> Toutes les adresses mails
                        </Btn>
                        <Btn
                            variant="primary"
                            type="button"
                            onClick={() => {
                                verifyToken()
                                navigator.clipboard.writeText(
                                    members
                                        .filter((mem) => mem.gender === 'F')
                                        .map((mem) => mem.email)
                                        .join(';')
                                )
                                sweetAlertSuccess('Les adresses mail sont copiées.')
                            }}
                        >
                            <IconBtn icon={faCopy} /> Adresses mails des femmes
                        </Btn>
                        <Btn
                            variant="primary"
                            type="button"
                            onClick={() => {
                                verifyToken()
                                navigator.clipboard.writeText(
                                    members
                                        .filter((mem) => mem.gender === 'H')
                                        .map((mem) => mem.email)
                                        .join(';')
                                )
                                sweetAlertSuccess('Les adresses mail sont copiées.')
                            }}
                        >
                            <IconBtn icon={faCopy} /> Adresses mails des hommes
                        </Btn>
                        <Btn
                            variant="primary"
                            type="button"
                            onClick={() => {
                                verifyToken()
                                navigator.clipboard.writeText(
                                    members
                                        .filter((mem) => +mem.dayOfBirth.split('/')[2] > 2006)
                                        .map((mem) => mem.email)
                                        .join(';')
                                )
                                sweetAlertSuccess('Les adresses mail sont copiées.')
                            }}
                        >
                            <IconBtn icon={faCopy} /> Adresses mails des jeunes (-16 ans)
                        </Btn>
                        <Btn variant="primary" type="button" onClick={() => setTournamentMoneyModal(true)}>
                            <IconBtn icon={faEuroSign} /> Modifier les comptes tournois
                        </Btn>
                        <Btn
                            variant="primary"
                            type="button"
                            onClick={() => {
                                verifyToken()
                                doResetTournamentMoney()
                            }}
                        >
                            <IconBtn icon={faEuroSign} /> Réinitialiser les comptes tournois
                        </Btn>
                    </BtnWrapper>
                </TitleWrapper>
                <SearchInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t('admin.search-member-input')}
                />
                <Table tableData={filterMembersToDisplay} tableHeader={tableHeaderMember} deleteById={deleteById} />
                {tournamentMoneyModal && (
                    <ModalStyled
                        centered
                        show={true}
                        onHide={() => {
                            setTournamentMoneyModal(false)
                            verifyToken()
                        }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Modifier le compte tournoi</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <TooltipBva
                                tootipText={
                                    <div
                                        style={{
                                            width: 250,
                                            padding: '1rem',
                                            background: 'black',
                                            borderRadius: 8,
                                        }}
                                    >
                                        {helpTextModel}
                                    </div>
                                }
                                placement="right"
                            >
                                <StyledButtonA href={files.model_compte_tournoi} className="btn btn-primary" download>
                                    Télécharger le model
                                </StyledButtonA>
                            </TooltipBva>

                            <ImportInput
                                type="file"
                                accept=".csv"
                                onChange={(e) => e.target?.files?.[0] && setImportFile(e.target.files[0])}
                            />

                            <StyledButton
                                variant="primary"
                                type="button"
                                onClick={() => {
                                    verifyToken()
                                    editTournamentMoney()
                                    setTournamentMoneyModal(false)
                                    setImportFile(undefined)
                                }}
                            >
                                Modifier
                            </StyledButton>
                        </Modal.Body>
                    </ModalStyled>
                )}
            </AdminContainer>
        </>
    )
}

const ModalStyled = styled(Modal)`
    .modal-title {
        font-size: 1.6rem;
    }
`

export const StyledButtonA = styled.a`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const HeaderBtnWrapper = styled.div`
    display: flex;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
    }
`

const IconBtn = styled(FontAwesomeIcon)`
    font-size: 2rem;
`

const BtnWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 8px;
    width: 75%;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
        width: 100%;
        flex-wrap: nowrap;
    }
`

const BvaBadge = styled(Badge)`
    display: flex;
    align-items: center;
    width: min-content;
    height: 28px;
    background: var(--primary-color) !important;
    margin-left: 8px;
`

const UserTableWrapper = styled.div`
    margin-bottom: 32px;
`

const TableTitle = styled(Title)`
    display: flex;
    width: 25%;
    align-items: center;
    margin-bottom: 8px;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        width: 100%;
    }
`

export const LinkBtn = styled(StyledButtonLink)`
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 1.6rem;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        margin-right: 0;
    }
`

const StyledButton = styled(Button)`
    font-size: 1.6rem;

    &:active {
        transform: scale(0.95);
    }
`

const Btn = styled(StyledButton)`
    margin-right: 8px;
    margin-bottom: 8px;

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        margin-right: 0;
        width: 100%;
    }
`

const ImportInput = styled.input`
    display: block;
    width: 100%;
    webkit-appearance: none;
    margin-right: 8px;
    margin-top: 16px;
    cursor: pointer;
    margin-bottom: 16px;
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    & .btn {
        height: 38px;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        flex-direction: column;
    }
`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 278px);
    padding: 24px;
    border-radius: 6px;
    background-color: white;
`

export default AdminUser
