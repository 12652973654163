import { useMutation, useQuery } from '@tanstack/react-query'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteDocumentById, getAll } from '../../../api/documentation.api'
import Loader from '../../../components/global/Loader'
import SearchInput from '../../../components/global/SearchInput'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { serverFile } from '../../../services/file.service'
import { DocumentModel } from '../../../types/document.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const AdminDocumentation = () => {
    const { t } = useTranslation('translation')

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()

    const tableHeader = ['#', 'Titre', 'Type', 'Description', 'Document', '', '']

    const [searchTerm, setSearchTerm] = useState('')
    const [filterDocuments, setFilterDocuments] = useState<any[]>()

    const { data: documents, refetch: refetchDocument } = useQuery({
        queryKey: ['document-admin-all'],
        queryFn: async () => {
            verifyToken()
            const docs = await getAll(lng)
            return docs.map((docItem: DocumentModel) => ({
                id: docItem.id,
                title: docItem.title,
                type: docItem.type,
                description: parse(docItem.description),
                file: (
                    <a href={docItem.file.includes('http') ? docItem.file : serverFile(docItem.file)} target="_blank">
                        {docItem.file}
                    </a>
                ),
                edit: `/${lng}/bva-admin/documentation/${docItem.id}`,
                remove: true,
            }))
        },
    })

    const { mutate } = useMutation({
        mutationFn: async (id: number) => {
            verifyToken()
            deleteDocumentById(id).then(() => {
                sweetAlertSuccess('La suppression de ce document est réussi').then(() => {
                    refetchDocument()
                })
            })
        },
    })

    useEffect(() => {
        setFilterDocuments(documents?.filter((doc) => doc.title.toLowerCase().includes(searchTerm.toLowerCase())))
    }, [searchTerm, documents])

    if (!documents || !filterDocuments) return <Loader />

    return (
        <>
            <TitleWrapper>
                <Title>Documentation</Title>
                <LinkBtn to={`/${lng}/bva-admin/documentation/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>

            <AdminContainer>
                <SearchInput
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t('member.search-document-input')}
                />
                <Table tableData={filterDocuments} tableHeader={tableHeader} deleteById={mutate} />
            </AdminContainer>
        </>
    )
}

export default AdminDocumentation
