import axios, { AxiosResponse } from 'axios'
import parse from 'html-react-parser'
import { useContext, useEffect, useState } from 'react'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { PriceModel } from '../../../types/price.type'
import { AdminContainer, LinkBtn, TitleWrapper } from '../users/AdminUsers'

const reductionMapper = (price: PriceModel) =>
    price.reductions?.map((r) => (
        <div key={`${price.id}-reduction-${r.name}`}>
            {r.name} : {r.priceReduction}
        </div>
    ))

const filteredPrices = (prices: PriceModel[], res: AxiosResponse<any, any>) =>
    prices.filter((price) => price.id !== res.data.id)

const AdminPrices = () => {
    const user = useContext(UserContext)
    const [prices, setPrices] = useState<PriceModel[] | undefined>(undefined)

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const tableHeader = ['#', 'Titre', 'Description', 'Prix', 'Prix de mi-saison', 'Reductions', '', '']

    useEffect(() => {
        verifyToken()
        if (!prices || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/prices/all`, { lng }, apiHeader(user.tokenAdmin))
                .then(({ data }) => {
                    setPrices(
                        data.map((price: PriceModel) => ({
                            id: price.id,
                            name: price.name,
                            description: parse(price.description),
                            price: price.price,
                            midSeasonPrice: price.midSeasonPrice,
                            reductions: reductionMapper(price),
                            edit: `/${lng}/bva-admin/tarifs/${price.id}`,
                            remove: true,
                        }))
                    )
                })
                .catch((err) =>
                    catchError(err.response.data, () => {
                        localStorage.removeItem('tokenAdmin')
                        user.adminData = undefined
                        window.location.reload()
                    })
                )
        }
    }, [prices, user, lng])

    if (!prices) return <Loader />

    const deleteById = (id: number) => {
        verifyToken()
        axios
            .delete(`${SERVER_URL}/prices/${id}`, apiHeader(user.tokenAdmin))
            .then((res) => {
                sweetAlertSuccess('La suppression de ce tarif est réussi').then(() => {
                    setPrices([...filteredPrices(prices, res)])
                })
            })
            .catch((err) =>
                catchError(err.response.data, () => {
                    localStorage.removeItem('tokenAdmin')
                    user.adminData = undefined
                    window.location.reload()
                })
            )
    }

    return (
        <>
            <TitleWrapper>
                <Title>Tarifs</Title>
                <LinkBtn to={`/${lng}/bva-admin/tarifs/creer`} className="btn btn-primary mb-2">
                    Créer
                </LinkBtn>
            </TitleWrapper>
            <AdminContainer>
                <Table tableData={prices} tableHeader={tableHeader} deleteById={deleteById} />
            </AdminContainer>
        </>
    )
}

export default AdminPrices
