import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import axios from 'axios'
import moment, { Moment } from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Label from '../../../components/form/Label'
import catchError from '../../../components/global/CatchError'
import Loader from '../../../components/global/Loader'
import Title from '../../../components/global/Title'
import { sweetAlertSuccess } from '../../../components/sweetalert/Alert'
import Table from '../../../components/table/Table'
import { StyledButtonLink } from '../../../components/table/Td'
import { SERVER_URL, apiHeader } from '../../../config'
import UserContext from '../../../context/UserContext'
import { useLangSelected } from '../../../hooks/useLangSelected'
import { useVerifyAdminToken } from '../../../hooks/useVerifyAdminToken'
import { Day, PlanningType, SessionFormType, SessionType } from '../../../types/planning.type'
import { StyledButton } from '../Login'
import { AdminContainer, TitleWrapper } from '../users/AdminUsers'
import { AdminForm } from '../users/AdminUsersForm'

const urlRegex = /^https?:\/\/www\.([a-z]|[0-9]|-)+\.([a-z]+)\/?((\/([a-z]|[0-9]|-)+)+)?(\?([a-z]|[0-9]|=|&)+)?$/

const translateDayToEn = (day: string | Day): Day => {
    day = day.toLowerCase()

    if (day === 'lundi') return 'monday'
    if (day === 'mardi') return 'tuesday'
    if (day === 'mercredi') return 'wednesday'
    if (day === 'jeudi') return 'thursday'
    if (day === 'vendredi') return 'friday'
    if (day === 'samedi') return 'saturday'
    if (day === 'dimanche') return 'sunday'

    return day as Day
}

const calculateDecimalTime = (time: Moment | string): number => {
    const momentTime = moment(time)
    const decimalTime = momentTime.hour() + momentTime.minute() / 60
    return decimalTime
}

export const calculateReelTime = (decimalTime: number): Moment => {
    const hours = Math.trunc(decimalTime)
    const minutes = Math.round((decimalTime - hours) * 60)

    const reelTime = `${hours.toLocaleString('fr-FR', { minimumIntegerDigits: 2 })}:${minutes.toLocaleString('fr-FR', {
        minimumIntegerDigits: 2,
    })}`

    return moment(reelTime, 'HH:mm')
}

const badmintonRoom: { [k in number]: string } = {
    1: 'Salle du blason',
    2: 'Salle des ESSUM',
}

const AdminPlanningsForm = () => {
    const user = useContext(UserContext)
    const location = useLocation()
    const params = useParams()

    const { t } = useTranslation('translation')

    const days = [
        t('global.monday'),
        t('global.tuesday'),
        t('global.wednesday'),
        t('global.thursday'),
        t('global.friday'),
        t('global.saturday'),
        t('global.sunday'),
    ]

    const verifyToken = useVerifyAdminToken()
    const lng = useLangSelected()
    const [currentLang, setCurrentLang] = useState(lng)

    const navigate = useNavigate()
    const BACK_LINK = `/${lng}/bva-admin/plannings`

    const [planning, setPlanning] = useState<PlanningType>({
        name: '',
        minTime: '',
        maxTime: '',
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    })

    const [isLoading, setIsLoading] = useState(true)
    const [sessionFormModal, setSessionFormModal] = useState<SessionFormType | undefined>(undefined)

    const isAddForm = location.pathname.includes('/bva-admin/plannings/creer')

    useEffect(() => {
        verifyToken()
        if ((!isAddForm && isLoading) || currentLang !== lng) {
            setCurrentLang(lng)
            axios
                .post(`${SERVER_URL}/plannings/one/${params.id}`, { lng }, apiHeader(user.tokenAdmin))
                .then(
                    ({
                        data,
                    }: {
                        data: {
                            name: string
                            page: string
                            minTime: number
                            maxTime: number
                            monday?: any
                            tuesday?: any
                            wednesday?: any
                            thursday?: any
                            friday?: any
                            saturday?: any
                            sunday?: any
                        }
                    }) => {
                        let formattedPlanning: PlanningType = {
                            name: data.name,
                            page: data.page,
                            minTime: calculateReelTime(data.minTime),
                            maxTime: calculateReelTime(data.maxTime),
                            monday: data.monday
                                ? JSON.parse(data.monday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            tuesday: data.tuesday
                                ? JSON.parse(data.tuesday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            wednesday: data.wednesday
                                ? JSON.parse(data.wednesday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            thursday: data.thursday
                                ? JSON.parse(data.thursday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            friday: data.friday
                                ? JSON.parse(data.friday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            saturday: data.saturday
                                ? JSON.parse(data.saturday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                            sunday: data.sunday
                                ? JSON.parse(data.sunday).map((session: SessionType) => ({
                                      name: session.name,
                                      start: calculateReelTime(session.start as number).format('HH:mm'),
                                      end: calculateReelTime(
                                          (session.start as number) + (session.during as number)
                                      ).format('HH:mm'),
                                      badmintonRoom: session.badmintonRoom,
                                  }))
                                : [],
                        }

                        setPlanning(formattedPlanning)
                        setIsLoading(false)
                    }
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            setIsLoading(false)
        }
    }, [isAddForm, isLoading, user.tokenAdmin, params.id, planning, lng])

    if (isLoading) return <Loader />

    const isValidate = planning.minTime && planning.maxTime && planning.name.length > 2

    const submit = () => {
        verifyToken()
        const dataToSend: any = {
            ...planning,
            minTime: calculateDecimalTime(planning.minTime),
            maxTime: calculateDecimalTime(planning.maxTime),
            monday:
                planning.monday?.map((session: SessionType) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            tuesday:
                planning.tuesday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            wednesday:
                planning.wednesday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            thursday:
                planning.thursday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            friday:
                planning.friday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            saturday:
                planning.saturday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
            sunday:
                planning.sunday?.map((session) => ({
                    ...session,
                    start: calculateDecimalTime(moment(session.start, 'HH:mm')),
                    end: calculateDecimalTime(moment(session.end, 'HH:mm')),
                    during:
                        calculateDecimalTime(moment(session.end, 'HH:mm')) -
                        calculateDecimalTime(moment(session.start, 'HH:mm')),
                })) || null,
        }

        if (isAddForm) {
            axios
                .post(`${SERVER_URL}/plannings`, dataToSend, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le planning a bien été créé.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        } else {
            dataToSend.lng = lng
            axios
                .patch(`${SERVER_URL}/plannings/${params.id}`, dataToSend, apiHeader(user.tokenAdmin))
                .then(() =>
                    sweetAlertSuccess(`Le planning a bien été modifié.`).then(() => {
                        navigate(BACK_LINK)
                    })
                )
                .catch((err) => {
                    catchError(err.response.data)
                })
        }
    }

    const editSession = (session: SessionFormType, day: string, index: number) => {
        setSessionFormModal({
            day,
            name: session.name,
            start: moment(session.start, 'HH:mm'),
            end: moment(session.end, 'HH:mm'),
            badmintonRoom: session.badmintonRoom,
            index,
        })
    }

    const removeSession = (day: Day | string, index: number) => {
        planning[day as Day] = planning[day as Day]?.filter((_session, i) => i !== index)
        setPlanning({ ...planning })
    }

    return (
        <>
            <TitleWrapper>
                <Title>{isAddForm ? 'Créer' : 'Modifier'} un planning</Title>
                <StyledButtonLink to={BACK_LINK} className="btn btn-primary mb-2">
                    Retour
                </StyledButtonLink>
            </TitleWrapper>
            <AdminContainer>
                <AdminForm>
                    <Form.Group className="mb-3">
                        <Label>Nom*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Nom*"
                            onChange={(e) => setPlanning({ ...planning, name: e.target.value })}
                            value={planning.name}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Label>Page d'affichage*</Label>
                        <Form.Control
                            type="text"
                            placeholder="Page d'affichage*"
                            onChange={(e) => setPlanning({ ...planning, page: e.target.value })}
                            value={planning.page || ''}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <TimeFormWrapper>
                            <TimePicker
                                label={<div>Heure de début du planning*</div>}
                                value={planning.minTime}
                                onChange={(value) => {
                                    if (value) setPlanning({ ...planning, minTime: value })
                                }}
                                renderInput={(params) => <CustomTextFieldTimePicker {...params} />}
                            />
                            <TimePicker
                                label={<div>Heure de fin du planning*</div>}
                                value={planning.maxTime}
                                onChange={(value) => {
                                    if (value) setPlanning({ ...planning, maxTime: value })
                                }}
                                renderInput={(params) => <CustomTextFieldTimePicker {...params} />}
                            />
                        </TimeFormWrapper>
                    </Form.Group>

                    {days.map((day: string, index: number) => (
                        <Form.Group className="mb-5" key={day}>
                            <DayHeader>
                                <Label>{day}</Label>
                                <StyledButton onClick={() => setSessionFormModal({ day })} variant="primary">
                                    Ajouter une séance
                                </StyledButton>
                            </DayHeader>
                            <Table
                                tableHeader={['Nom', 'Heure de début', 'Heure de fin', 'Salle']}
                                tableData={
                                    planning[translateDayToEn(day)]?.map((session: SessionType, index: number) => ({
                                        name: session.name,
                                        start: moment(session.start, 'HH:mm').format('HH:mm'),
                                        end: moment(session.end, 'HH:mm').format('HH:mm'),
                                        badmintonRoom: badmintonRoom[session.badmintonRoom],
                                        edit: () => editSession(session, t(day), index),
                                        remove: () => removeSession(t(day), index),
                                    })) || []
                                }
                                noDataMessage="Aucun créneau enregistré"
                            />
                        </Form.Group>
                    ))}
                    <StyledButton variant="primary" type="button" disabled={!isValidate} onClick={submit}>
                        {isAddForm ? 'Créer' : 'Modifier'}
                    </StyledButton>
                </AdminForm>

                {sessionFormModal && (
                    <Modal centered show={true} onHide={() => setSessionFormModal(undefined)}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Ajouter une séance le {sessionFormModal.day}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AdminForm>
                                <Form.Group className="mb-3">
                                    <Label>Nom de la séance</Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom de la séance"
                                        onChange={(e) => {
                                            setSessionFormModal({ ...sessionFormModal, name: e.target.value })
                                        }}
                                        value={sessionFormModal.name || ''}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <TimeFormWrapper>
                                        <TimePicker
                                            label={<div>Heure de début</div>}
                                            value={sessionFormModal.start || ''}
                                            onChange={(value) => {
                                                setSessionFormModal({
                                                    ...sessionFormModal,
                                                    start: value,
                                                })
                                            }}
                                            renderInput={(params) => <CustomTextFieldTimePicker {...params} />}
                                        />
                                        <TimePicker
                                            label={<div>Heure de fin</div>}
                                            value={sessionFormModal.end || ''}
                                            onChange={(value) => {
                                                setSessionFormModal({
                                                    ...sessionFormModal,
                                                    end: value,
                                                })
                                            }}
                                            renderInput={(params) => <CustomTextFieldTimePicker {...params} />}
                                        />
                                    </TimeFormWrapper>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Label>Nom de la séance</Label>
                                    <Form.Select
                                        onChange={(e) => {
                                            setSessionFormModal({ ...sessionFormModal, badmintonRoom: +e.target.value })
                                        }}
                                        defaultValue={sessionFormModal.badmintonRoom || 0}
                                    >
                                        <option value={0}>Sélectionnez une salle</option>
                                        {Object.entries(badmintonRoom).map((badRoom) => (
                                            <option key={badRoom[0]} value={badRoom[0]}>
                                                {badRoom[1]}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <StyledButton
                                    variant="primary"
                                    type="button"
                                    disabled={
                                        !sessionFormModal.name || !sessionFormModal.start || !sessionFormModal.end
                                    }
                                    onClick={() => {
                                        const { day, name, start, end, badmintonRoom, index } = sessionFormModal

                                        if (day) {
                                            const translatedDay = translateDayToEn(day)
                                            const planningToUpdate = planning[translatedDay]
                                            if (
                                                name &&
                                                start &&
                                                end &&
                                                badmintonRoom !== undefined &&
                                                planningToUpdate
                                            ) {
                                                if (index !== undefined && index >= 0) {
                                                    planningToUpdate[index] = {
                                                        name,
                                                        start: moment(start),
                                                        end: moment(end),
                                                        badmintonRoom,
                                                    }
                                                } else {
                                                    planningToUpdate.push({
                                                        name,
                                                        start: moment(start),
                                                        end: moment(end),
                                                        badmintonRoom,
                                                    })
                                                }
                                            }
                                            setPlanning({ ...planning })
                                        }
                                        setSessionFormModal(undefined)
                                    }}
                                >
                                    Enregistrer la séance
                                </StyledButton>
                            </AdminForm>
                        </Modal.Body>
                    </Modal>
                )}
            </AdminContainer>
        </>
    )
}
const DayHeader = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin: 16px 0;

    & label {
        display: flex;
        align-items: center;
        width: 100px;
        height: 100%;
    }
`

const TimeFormWrapper = styled.div`
    display: flex;
`

const CustomTextFieldTimePicker = styled(TextField)`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    height: 33px;

    input,
    input::placeholder {
        font-size: 1.6rem;
        height: 33px;
        display: flex;
        align-items: center;
    }

    &:first-of-type {
        margin-right: 16px;
    }

    & label[data-shrink='false'] {
        display: flex;
        align-items: center;
        position: absolute;
        top: -16px;
        color: black;
    }

    & > label {
        height: 33px;
    }

    & > label + div {
        height: 33px;
    }
`

export default AdminPlanningsForm
