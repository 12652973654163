import styled from '@emotion/styled'
import parse from 'html-react-parser'
import moment from 'moment'
import Breakpoint from '../../Breakpoint'
import CardLink from './CardLink'

const PriceCard = ({
    title,
    price,
    midSeasonPrice,
    reductions,
    description,
}: {
    title: string
    price: number
    midSeasonPrice: number
    description: string
    reductions?: { name: string; priceReduction: number }[]
}) => {
    return (
        <PriceCardContainer>
            <PriceCardSubTitle>
                {title} {moment(Date.now()).isAfter(moment(new Date('2025-01-16'))) && 'mi-saison'}
            </PriceCardSubTitle>
            <PriceCardTitle>{price || midSeasonPrice}€</PriceCardTitle>
            <PriceCardDescription>{parse(description)}</PriceCardDescription>
            <Reductions>
                {reductions?.map((reduction) => (
                    <Reduction
                        key={`reduction-${reduction.name}`}
                    >{`${reduction.name}: ${reduction.priceReduction}€`}</Reduction>
                ))}
            </Reductions>
            <PriceCardLinkContainer>
                <CardLink href="https://adherer.ffbad.club/BVA59" target="_blank">
                    {"S'inscrire"}
                </CardLink>
            </PriceCardLinkContainer>
        </PriceCardContainer>
    )
}

const PriceCardTitle = styled.h2`
    text-align: center;
    font-size: 4.8rem;
    margin-top: 16px;
    color: var(--primary-color);
    font-family: 'Roboto Semi Bold';
`

const PriceCardSubTitle = styled.h2`
    text-align: center;
    font-size: 3rem;
    margin-top: 8px;
    margin-bottom: 16px;
    color: var(--primary-color);
    font-family: 'Roboto Semi Bold';
`

const Reductions = styled.div`
    margin-bottom: 16px;
`

const Reduction = styled.div`
    color: var(--tertiary-color);
`

const PriceCardDescription = styled.div`
    text-align: justify;
    line-height: 22px;
    min-height: 80px;
    overflow: auto;
    padding-right: 13px;
    margin-bottom: 5px;
`

const PriceCardContainer = styled.article`
    position: relative;
    width: 98%;
    height: auto;
    display: flex;
    flex-direction: column;
    background: white;
    padding: 16px;
    border-radius: 6px;
    margin-right: 5px;
    box-sizing: border-box;
    margin-bottom: 16px;
    overflow: hidden;

    @media (max-width: ${Breakpoint.LAPTOP_XS}) {
        margin-bottom: 16px;
    }
`

const PriceCardLinkContainer = styled.div`
    display: flex;
    text-decoration: none;
    border-radius: 4px;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
`

export default PriceCard
