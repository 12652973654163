import styled from '@emotion/styled'
import Dropzone from 'react-dropzone'

export const DropZoneCustom = ({
    setImage,
    image,
    currentImage,
}: {
    setImage: (image: File[]) => void
    image: File[]
    currentImage?: string
}) => {
    return (
        <Dropzone onDrop={(image: File[]) => setImage(image)} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}>
            {({ getRootProps, getInputProps }) => (
                <DropZoneSection className="dropzone">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Dépose ton image ici ou clique sur la zone pour sélectionner une image</p>
                        {(image.length > 0 || currentImage) && (
                            <div>
                                <h4>Prévisualisation</h4>
                                <img
                                    src={image[0] ? URL.createObjectURL(image[0]) : currentImage}
                                    alt="Prévisualisation de l'image"
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </DropZoneSection>
            )}
        </Dropzone>
    )
}

const DropZoneSection = styled.section`
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
`
