import styled from '@emotion/styled'
import logo_beewac from '../../assets/img/beewac-logo.png'
import logo_esprit_bad from '../../assets/img/logo-esprit-bad.png'
import logo_ffbad from '../../assets/img/logo-ffbad.svg'
import logo_mairie_vda from '../../assets/img/Logo-VDA.png'
import logo_oms_vda from '../../assets/img/Logo_OMS.png'
import Breakpoint from '../../Breakpoint'

const Carousel = () => {
    const partners = [
        { name: 'Esprit bad', description: '', logo: logo_esprit_bad, link: 'https://www.espritbadminton.com/' },
        { name: 'Beewac', description: '', logo: logo_beewac, link: 'https://www.beewac.com/' },
        {
            name: "OMS de Villeneuve d'Ascq",
            description: '',
            logo: logo_oms_vda,
            link: 'http://www.sport-omsvdascq.fr/',
        },
        {
            name: "Mairie de Villeneuve d'Ascq",
            description: '',
            logo: logo_mairie_vda,
            link: 'https://www.villeneuvedascq.fr/',
        },
        {
            name: 'La Fédération française de badminton',
            description: '',
            logo: logo_ffbad,
            link: 'https://www.ffbad.org/',
        },
    ]

    return (
        <MainCarouselContainer>
            <CarouselContainer>
                <CarouselWrapper className="carousel-1">
                    {partners.map((partner, index) => (
                        <Partner
                            key={'partner1-' + partner.name + index}
                            href={partner.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={partner.logo} alt={partner.link} />
                        </Partner>
                    ))}
                </CarouselWrapper>
                <CarouselWrapper className="carousel-2">
                    {partners.map((partner, index) => (
                        <Partner
                            key={'partner2-' + partner.name + index}
                            href={partner.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={partner.logo} alt={partner.link} />
                        </Partner>
                    ))}
                </CarouselWrapper>
                <CarouselWrapper className="carousel-3">
                    {partners.map((partner, index) => (
                        <Partner
                            key={'partner3-' + partner.name + index}
                            href={partner.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={partner.logo} alt={partner.link} />
                        </Partner>
                    ))}
                </CarouselWrapper>
            </CarouselContainer>
        </MainCarouselContainer>
    )
}

const MainCarouselContainer = styled.div`
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 0px 6px black;
`

const CarouselContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300%;

    @media (max-width: ${Breakpoint.TABLET_L}) {
        width: 400%;
    }

    @media (max-width: ${Breakpoint.TABLET_XS}) {
        width: 600%;
    }

    @media (max-width: ${Breakpoint.PHONE}) {
        width: 950%;
    }
`

const CarouselWrapper = styled.div`
    display: flex;
    justify-content: space-around;

    background-color: white;

    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-scroll-chaining: none;
    -webkit-scroll-snap-type: x mandatory;

    width: 100%;
    animation: carousel 15s linear infinite;

    @keyframes carousel {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
`

const Partner = styled.a`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;

    img {
        max-height: 75px;
    }
`

export default Carousel
